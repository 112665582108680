jQuery(function($) {
	/**
	 * Widget Labels
	 */
	if($('body').is('.widgets.widgetize')) {
		var labels = [],
			lastLabelButton,
			widget_id,
			_nonce = $('#_widget_nonce').val(),
			getContrast = function(hexcolor){
				hexcolor = hexcolor.replace('#', '');
				var r = parseInt(hexcolor.substr(0,2),16);
				var g = parseInt(hexcolor.substr(2,2),16);
				var b = parseInt(hexcolor.substr(4,2),16);
				var yiq = ((r*299)+(g*587)+(b*114))/1000;
				return (yiq >= 128) ? 'black' : 'white';
			},
			refreshLabelList = function() {
				var labelsElement = $('.label-selector .label-list');

				// Remove all labels
				labelsElement.html('');

				for(var k in labels){
				    if(labels.hasOwnProperty(k)) {
						var label = $('<div />').addClass('label-single').attr('data-id', labels[k].label_id).css('background', labels[k].label_options.color).css('color', getContrast(labels[k].label_options.color)).html('<span>'+labels[k].label_title+'</span>').append('<i class="material-icons">settings</i>');
						labelsElement.append(label);
				    }
				}
			},
			doingLabelAjax = null,
			labelAjax = function(args, success) {
				success = success || function() {};

				if(!args.nonce) {
					args.nonce = _nonce;
				}

				if(doingLabelAjax) {
					return;
				}
				doingLabelAjax = true;
				$('.label-selector').append('<div class="loading"><div class="lds-hourglass"><div></div>');

				$.ajax({
					url: OrderForms.ajaxurl,
					cache: false,
					data: args,
					method: 'POST',
					dataType: 'json',
					success: function(output) {
						doingLabelAjax = null;
						$('.label-selector >.loading').remove();
						success(output);
					}
				});
			},
			resetForm = function() {
				$('#label-title, .label-form input[type="hidden"][name="label_id"]').val('');
				$('.colorpicker-component input').val($('.colorpicker-component input').attr('data-color')).change();

				$('.label-selector .loading').remove();

				$('.label-action, .label-form .label-form-actions .label-add').show();
				$('.label-form, .label-form .label-form-actions .label-delete, .label-form .label-form-actions .label-edit, .label-action .label-clear').hide();

				if(lastLabelButton && lastLabelButton.is('[data-label]')) {
					$('.label-action .label-clear').show();
				}
			},
			getLabel = function(label_id) {
				var label = null;
			    for (var i in labels) {
			        if(!labels.hasOwnProperty(i)) {
						continue;
					}
			        if(typeof labels[i] === 'object' && labels[i].label_id === label_id) {
			            label = labels[i];
						break;
			        }
			    }
			    return label;
			},
			updateWidgetLabels = function(label) {
				label = getLabel(label);
				var labels = $('.widget-label button[name="add-label"][data-label="'+label.label_id+'"]');
				labels.each(function() {
					$(this).css({
						background: label.label_options.color,
						color: getContrast(label.label_options.color)
					}).text(label.label_title);
				});
			},
			deleteWidgetLabels = function(label) {
				var labels = $('.widget-label button[name="add-label"][data-label="'+label+'"]');
				labels.each(function() {
					$(this).css('cssText', '').text('Add Label').removeAttr('data-label');
				});
			};

		$('.colorpicker-component').colorpicker({
			format: 'hex',
			customClass: 'colorpicker-2x',
			sliders: {
	            saturation: {
	                maxLeft: 150,
	                maxTop: 150
	            },
	            hue: {
	                maxTop: 150
	            },
	            alpha: {
	                maxTop: 150
	            }
	        }
		});

		labelAjax({action: 'get_labels'}, function(output) {
			labels = output;
			refreshLabelList();
		});

		$(document).on('click', 'button[name="add-label"]', function() {
			var $this = $(this),
				offset = $this.offset(),
				offsetTop = $this.offset().top + $this.outerHeight(),
				offsetLeft = $this.offset().left,
				ele = $('.label-selector'),
				labelsEle = $('<div class="labels" />');

			lastLabelButton = $(this);

			// Reset displays
			$('.label-form-actions .label-delete, .label-action .label-cancel, .label-form, .label-edit, .label-action .label-clear').hide();
			$('.label-action').show();

			if($this.is('[data-label]')) {
				$('.label-action .label-clear').show();
			}

			widget_id = $(this).closest('.widget').attr('data-id');

			ele.removeClass('filter-label');

			ele.css({
				top:     offsetTop,
				left:    offsetLeft,
				display: 'block'
			});
		}).on('click', 'button[name="filter-label"]', function() {
			var $this = $(this),
				offset = $this.offset(),
				offsetTop = $this.offset().top + $this.outerHeight(),
				offsetLeft = $this.offset().left,
				ele = $('.label-selector');

			// Reset displays
			$('.label-form-actions .label-delete, .label-action .label-cancel, .label-form, .label-edit, .label-action .label-clear').hide();

			ele.addClass('filter-label');
			ele.css({
				top:     offsetTop,
				left:    offsetLeft,
				display: 'block'
			});
		}).on('click', '.label-selector .label-action .label-new', function(e) {
			e.preventDefault();
			$('.label-action').hide();
			$('.label-form').show();
		}).on('click', '.label-selector .label-action .label-clear', function(e) {
			e.preventDefault();

			labelAjax({action: 'clear_label', widget_id: widget_id}, function(output) {
				if(output.success) {
					lastLabelButton.closest('.widget').attr('data-label', '');
					lastLabelButton.css('cssText', '').text('Add Label').removeAttr('data-label');
					$('.label-selector').hide();
				}
			});
		}).on('click', '.label-selector .label-form-actions .label-cancel', function(e) {
			e.preventDefault();
			resetForm();
		}).on('click', function(e) {
			var $this = $(e.target);
				labelSelector = $this.closest('.label-selector'),
				colorPicker = $this.closest('.colorpicker'),
				filterLabel = $this.closest('button');

			if($this.attr('name') || filterLabel.attr('name') && ($this.attr('name') === 'add-label' || ($this.attr('name') === 'filter-label' || filterLabel.attr('name') === 'filter-label' && !$this.hasClass('clear')))) {
				return;
			}

			if(labelSelector.length === 0 && colorPicker.length !== 1 && $('.label-selector').is(':visible')) {
				$('.label-selector').hide();
				$('.colorpicker.dropdown-menu').addClass('colorpicker-hidden');
				resetForm();
			}
		}).on('click', '.label-selector .label-single', function(e) {
			e.preventDefault();
			if($(e.target).hasClass('material-icons')) {
				return;
			}

			if($('.label-selector').hasClass('filter-label')) {
				var style = $(this).attr('style'),
					label  = $(this).attr('data-id'),
					text   = $(this).find('span').text(),
					button = $('button[name="filter-label"]');

				button.addClass('selected');
				button.attr('style', style);
				button.find('span.label').text(text);
				$('.widget-list > .widget').hide();
				$('.widget-list > .widget[data-label='+label+']').show();
				$('.label-selector').hide();
			} else {
				var label_id = $(this).attr('data-id'),
					label = getLabel(label_id),
					widget_id = lastLabelButton.closest('.widget').attr('data-id');

				labelAjax({action: 'add_label_to_widget', label_id: label_id, widget_id: widget_id}, function(output) {
					if(output.success) {
						lastLabelButton.closest('.widget').attr('data-label', label.label_id);
						lastLabelButton.replaceWith('<button type="button" name="add-label" class="btn-label" data-label="'+label.label_id+'" style="background: '+label.label_options.color+'; color: '+getContrast(label.label_options.color)+'">'+label.label_title+'</button>');
						$('.label-selector').hide();
					}
				});
			}
		}).on('click', 'button[name="filter-label"] .clear', function(e) {
			e.preventDefault();

			$(this).closest('button').attr('style', '');
			$(this).closest('button').removeClass('selected');
			$(this).closest('button').find('span.label').text('Label');
			$('.widget-list > .widget').show();
		}).on('click', '.label-selector .label-single .material-icons', function(e) {
			e.preventDefault();
			var label_id = $(this).parent().attr('data-id'),
				label = getLabel(label_id);

			$('.label-form input[type="hidden"][name="label_id"]').val(label.label_id);
			$('#label-title').val(label.label_title);
			$('.colorpicker-component input').val(label.label_options.color).change();

			$('.label-action, .label-form .label-form-actions .label-add').hide();
			$('.label-form, .label-form .label-form-actions .label-delete, .label-form .label-form-actions .label-edit').show();
		}).on('click', '.label-selector .label-form-actions .label-add', function(e) {
			e.preventDefault();
			var args = $('.label-selector .label-form :input').serialize(),
				label_id = $(this).closest('.label-form').find('input[name="label_id"]').val();

			labelAjax({action: 'add_label', args: args}, function(output) {
				labels = output;
				refreshLabelList();
				resetForm();
				if(label_id) {
					updateWidgetLabels(label_id);
				}
			});
		}).on('click', '.label-selector .label-form-actions .label-delete', function(e) {
			e.preventDefault();
			var label_id = $(this).closest('.label-form').find('input[name="label_id"]').val(),
				conf = confirm('Are you sure you want to delete this label?');

			if(!conf) {
				return;
			}

			labelAjax({action: 'delete_label', label_id: label_id}, function(output) {
				labels = output;
				refreshLabelList();
				resetForm();
				deleteWidgetLabels(label_id);
			});
		}).keyup(function(e) {
			if(e.keyCode === 27 && $('.label-form').is(':visible')) {
				resetForm();
			} else if(e.keyCode === 27 && $('.label-selector').is(':visible')) {
				$('.label-selector').hide();
				$('.colorpicker.dropdown-menu').addClass('colorpicker-hidden');
				resetForm();
			}
		});;
	}
});
