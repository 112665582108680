function orderFormsPopup(args) {
	this.args = jQuery.extend({}, {
		content:     '',
		title: 		 '',
		close:       true,
		onShow:      function() {},
		onClose:     function() {},
		customClose: false,
		class:       ''
	}, args);
	this.create = function() {
		// Close previous popupd
		this.close();

		this.ele = jQuery('<div id="orderform-popup" class="'+this.args.class+'" style="display: none;"><div class="popup-content"></div></div>');

		// Add close function
		if(this.args.close) {
			this.closeElement = jQuery('<div class="popup-close"><i class="material-icons">close</i></div>');
			this.ele.prepend(this.closeElement);

			var popup = this;

			popup.closeElement.on('click', function() {
				popup.close();
			});
		}
		console.log(this.args.title);
		if(this.args.title) {
			this.ele.find('.popup-content').append('<div class="popup-title">'+this.args.title+'</div>');
		}
		this.ele.find('.popup-content').html(this.args.content);
		jQuery('body').append(this.ele);

		this.args.onShow();
	};

	this.close = function() {
		jQuery('#orderform-popup').remove();
		jQuery('body').removeClass('pop-active');

		this.args.onClose();
	};

	this.display = function() {
		this.create();

		this.ele.show();

		jQuery('body').addClass('pop-active');

		return this;
	};

	this.updatePopup = function(content, title, classes) {
		classes = classes || '';

		this.ele.find('.popup-content').html(content);
		if(title) {
			this.ele.find('.popup-content').prepend('<div class="popup-title">'+title+'</div>');
		}
		this.ele.attr('class', classes);
	}

	return this.display();
}

jQuery(function($) {

    $(document).on('click', function(e) {

    }).on('click', '.widget-actions .btn', function(e) {
        if($(this).attr('data-action') === 'delete') {
			var form = $(this).next(),
				conf = confirm('Are you sure you want to delete this widget?');
            if(conf) {
				form.submit();
			}
        }
    });

	/**
	 * Widget Preview
	 */

	// Reload Iframe
	$(document).on('click', '.preview-buttons .preview-refresh', function() {
		$('.preview-iframe iframe').attr('src', $('.preview-iframe iframe').attr('src'));
	});

	// Reload Iframe
	$(document).on('click', '.preview-buttons .preview-rotate', function() {
		var resolutionEle = $('.preview-resolution span'),
			resolution = resolutionEle.text().replace(/ /g, '').split('x'),
			iframe     = $('.preview-iframe iframe');

		iframe.css({
			height: resolution[0],
			width: resolution[1]
		});

		resolutionEle.text(resolution[1]+' x '+resolution[0]);
	});

	// Resolution
	$(document).on('click', '.preview-buttons .dropdown a', function() {
		var resolutionEle = $('.preview-resolution span'),
			resolution = $(this).attr('data-thing').split('x'),
			iframe     = $('.preview-iframe iframe');

		iframe.css({
			width: resolution[0],
			height: resolution[1]
		});

		resolutionEle.text(resolution[0]+' x '+resolution[1]);
	});

	/**
	 * Widget Code
	 */
	$(document).on('focus', 'body.code.widgetize textarea.widget-code', function(e) {
		$(this).select();
	});
	$(document).on('click', 'body.code.widgetize .content .actions .btn', function(e) {
		if($(this).attr('data-action') === 'copy-code') {
 			var code = $(this),
				offset = code.offset(),
				offsetTop = code.offset().top + (code.outerHeight() * .5) - 8,
				offsetLeft = code.offset().left + code.outerWidth() + 5,
 				copy = null;
 			$('textarea.widget-code').select();
 			try {
 				copy = document.execCommand('copy');
 				if(copy) {
 					var notify = $('<div class="notify text-success">Copied!</div>');
 					$('body').append(notify);
 					notify.css({
 						position: 'absolute',
 						top: offsetTop,
 						left: offsetLeft,
 						'line-height': 1
 					}).animate({
 						opacity: 0,
 						top: "-=50"
 					}, 1000, function() {
 						notify.remove();
 					});

 				}
 		 	} catch(err) {
 				console.log('ERR: Not Copy');
 		 	}
		}
	});

	var doingStatsAjax = null;
	function getGraph(start, end) {
		var widget = window.location.pathname.split('/');

		widget = $.grep(widget,function(n){ return n === 0 || n });
		widget = widget[widget.length -1];

		$('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));

		if(doingStatsAjax) {
			return;
		}

		doingStatsAjax = true;

		$.ajax({
			url: OrderForms.ajaxurl,
			cache: false,
			data: {action: 'get_analysis', start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), widget: widget, nonce: $('#_widget_stats_nonce').val()},
			method: 'POST',
			dataType: 'json',
			success: function(output) {
				doingStatsAjax = null;

				console.log(output);

				$('.analytics').html('<canvas id="countChart" class="w-100"></canvas><canvas id="conversionChart" class="w-100"></canvas>');

				var raw = $('<div class="raw"></div>');
				raw.append('<h4>'+output.rawLabel+'</h4>');
				for(var i = 0; i < output.raw.length; i++) {
				    raw.append('<div>'+output.raw[i].label+': '+output.raw[i].count+'</div>');
				}
				$('.analytics').prepend(raw);

				var count = document.getElementById('countChart').getContext('2d'),
					conversion = document.getElementById('conversionChart').getContext('2d');

				new Chart(count, {
					type: 'bar',
					data: {
						labels: output.labels,
						datasets: output.count
					},
					options: {
						tooltips: {
							mode: 'index',
							intersect: false
						},
						responsive: true,
						scales: {
							xAxes: [{
								ticks: {
									beginAtZero:true
								}
							}]
						}
					}
				});

				new Chart(conversion, {
					type: 'bar',
					data: {
						labels: output.labels,
						datasets: output.conversion
					},
					options: {
						title:{
							display: true,
							text: 'Conversion'
						},
						tooltips: {
							mode: 'label',
							intersect: false,
							callbacks: {
								label: function(tooltipItems, data) {
									return data.datasets[tooltipItems.datasetIndex].label +': ' + tooltipItems.yLabel + '%';
								}
							}

						},
						responsive: true,
						scales: {
							yAxes: [{
								ticks: {
									min: 0,
									max: 100,
								},
								scaleLabel: {
									display: true,
									labelString: "Percentage"
								}
							}]
						}
					}
				});
			}
		});
	}

	/**
	 * Widget Stats
	 */
	if($('body').is('.widgetize.stats')) {
		var start = moment().subtract(7, 'days');
	    var end = moment().subtract(1, 'days');

	    $('#reportrange').daterangepicker({
	        startDate: start,
	        endDate: end,
			maxDate: moment(),
	        ranges: {
	           'Today': [moment(), moment()],
	           'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
	           'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
	           'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
	           'This Month': [moment().startOf('month'), moment().subtract(1, 'days')],
	           'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	        }
	    }, function(start, end, label) {
	    	getGraph(start, end);
			console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
		});

	    getGraph(start, end);
	}

	/**
	 * Notice close
	 */
	$('.of-notices .alert').on('closed.bs.alert', function () {
		var notice_id = $(this).attr('data-id'),
			nonce = $(this).find('button input').val();

		$.post(OrderForms.ajaxurl, {
			action: 'notice_close',
			notice_id: notice_id,
			notice_nonce: nonce
		});
	})

	/**
	 * Preview
	 */
	$(document).on('click', '.widget button[data-action="preview"]', function(e) {
		e.preventDefault();

		var widget_id = $(this).closest('.widget').attr('data-id'),
			nonce = $(this).next().val();

		var pop = new orderFormsPopup({
			content: '<div class="popup-loading"><div class="lds-hourglass black"><div></div>',
			class: 'loading'
		});

		$.ajax({
			url: OrderForms.ajaxurl,
			cache: false,
			data: {
				action: 'get_preview',
				widget_id: widget_id,
				nonce: nonce
			},
			method: 'POST',
			dataType: 'json',
			success: function(output) {
				if(output.success) {
					pop.updatePopup(output.html, '', 'preview-pop');
					var s  = document.createElement("script");
					s.type = "text/javascript";
					s.src  = output.js;

					// var iframe = $('<iframe src="" frameborder="0" class="" style="width: 1024px; height: 600px"></iframe>').appendTo('.preview-iframe .widget-preview').contents().find('body').append(output.preview).append(s);
				} else {
					pop.close();
				}
			}
		});
	});
});
